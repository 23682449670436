import { Redirect, Route } from "react-router-dom";

function PrivateRoute({ component: Component,appName, ...rest }) {
  if(appName === "Hero"){
    return (
      <Route
        {...rest}
        render={(props) =>
          // localStorage.getItem("loggedIn")
          (localStorage.getItem("loggedIn") === "true")
           ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: "/HeroLogin", state: { from: props.location } }}
            />
          )
        }
      />
    );

  }
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("loggedIn") && localStorage.getItem("SH_Account_Id") && localStorage.getItem("SH_access_token") && localStorage.getItem("SH_refresh_token") && localStorage.getItem("SH_user_type")
         ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;