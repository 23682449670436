import React, { lazy, Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './App.css';
import ProtectedRoute from './ProtectedRoute'
import { Switch, Route } from 'react-router-dom';
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 


const Login= lazy(() => import('./Containers/LoginPage/Login'));
const LoginWithBranding= lazy(() => import('./Containers/LoginPage/LoginWithBranding'));
const PaymentInvoice= lazy(() => import('./Containers/PaymentInvoice/PaymentInvoice'));
const ResponsePage= lazy(() => import('./Containers/ResponsePages/ResponsePage'));
const NotFound= lazy(() => import('./Containers/NotFound/NotFound'));
const ResponsePageWithCrossSell= lazy(() => import('./Containers/ResponsePages/ResponsePageWithCrossSell'));
const Dashboard= lazy(() => import('./Containers/Dashboard/Dashboard'));
const GenericLinkPage= lazy(() => import('./Containers/GenericLinkPage/GenericLinkPage'));
const InvoicePayResponsePage= lazy(() => import('./Components/ResponsePageInvoice/InvoicePayResponsePage'));
const HeroLogin= lazy(() => import('./Components/HeroHousing/HeroLogin/HeroLogin'));
const HeroInvoice= lazy(() => import('./Components/HeroHousing/HeroInvoice/HeroInvoice'));
const HeroResponsePage= lazy(() => import('./Components/HeroHousing/HeroResponsePage/HeroResponsePage'));
const InvoicePage= lazy(() => import('./Components/InvoicePayComponents/Transaction/InvoicePage'));
const SetPassword = lazy(() => import('./Components/Common/SetPassword/SetPassword'));


toast.configure()


function App() {
  return (
    <div className="App">
      <Suspense fallback={<div class="center-screen">
        <span class="loader"></span>
        <div>Loading....</div>
        </div>}>
      <Switch> 
        <Route exact path='/PaymentInvoice/:invoice_id' render={(props) => <PaymentInvoice {...props}/>}/>
        <Route exact path='/PaymentInvoice/:merchant_id/:uuid' render={(props) => <InvoicePage {...props}/>}/> //Route for InvoicePay Invoice
        <Route exact path='/HeroLogin' component={HeroLogin}/>
        <ProtectedRoute path='/HeroInvoice/:merchant_id/:id' component={HeroInvoice} appName="Hero"/>
        <Route exact path='/HeroResponsePage/:txn_id' render={(props) => <HeroResponsePage {...props}/>}/>
        <ProtectedRoute path='/Dashboard' component={Dashboard}/>
        <Route exact path='/InvoicePayResponsePage/:txn_id' render={(props) => <InvoicePayResponsePage {...props}/>}/>
        <Route exact path='/ResponsePage/:txn_id' render={(props) => <ResponsePage {...props}/>}/>
        <Route exact path='/ResponsePage_' component={ResponsePageWithCrossSell}/>
        <Route exact path='/GenericLinkPage/:form_id' render={(props) => <GenericLinkPage {...props}/>}/>
        <Route exact path='/SetPassword/:token' render={(props) => <SetPassword {...props}/>} />
        <Route exact path='/:merchant_name/:merchant_id' render={(props) => <LoginWithBranding {...props}/>}/>
        <Route exact path='/SetPassword/:merchant_id/:token' render={(props) => <SetPassword {...props}/>} />
        <Route exact path='/' component={Login}/>
        <Route render={(props) => <NotFound {...props} link='/'/>}/>
      </Switch>
      </Suspense>
    </div>

  );
}
export default App;